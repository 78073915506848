import React from 'react'
import Img from 'gatsby-image'
import Wrapper from '../styles/Wrapper'
import Section from '../styles/Section'
import Box from '../styles/Box'
import Button from '../components/Button'
import Header from '../components/Header'
import Prices from '../components/Prices'
import ReadMore from '../components/ReadMore'
import headerImage from '../images/header.jpg'
import { media } from '../styles/minWidth'
import { colors } from '../styles/variables'
import Layout from '../components/layout'
import { graphql } from 'gatsby'

const introBreakpoint = '@media(min-width: 900px)'

const Intro = ({ data }) => (
  <Section>
    <Wrapper contentWidth={1200}>
      <div
        css={{
          textAlign: 'center',
          marginBottom: '2.5em',
          [introBreakpoint]: {
            display: 'flex',
            textAlign: 'left',
          },
        }}
      >
        <div
          css={{
            marginBottom: 40,
            [introBreakpoint]: {
              marginBottom: 0,
              marginRight: 80,
            },
          }}
        >
          <h2
            css={{
              marginLeft: 'auto',
              marginRight: 'auto',
              maxWidth: 650,
              [introBreakpoint]: {
                marginLeft: 0,
              },
            }}
          >
            Persoonlijk advies op maat over voeding en leefstijl
          </h2>

          <p>
            Ik ben Lieke Angenent, diëtist en oprichter van Friska. Mijn doel
            met Friska is om mensen handvatten te bieden tot een vitaal leven,
            door middel van persoonlijk advies op maat over voeding en
            leefstijl.
          </p>
          <p>
            Een gezonde leefstijl biedt de basis voor een gezond lijf, maar ook
            voor een gezonde geest. Hierdoor geef je jezelf de ruimte om je
            verder te ontplooien en te genieten van je leven.
          </p>

          <Button type="ghost" to="/over-friska">
            Lees meer over mij
          </Button>
        </div>

        <div
          css={{
            margin: '0 auto',
            maxWidth: 320,
            [introBreakpoint]: {
              minWidth: 320,
            },
          }}
        >
          <Img fluid={data.lieke.childImageSharp.fluid} />
        </div>
      </div>

      <div css={{ textAlign: 'center' }}>
        <h4>Aangesloten bij</h4>
        <div
          css={{
            '> :first-of-type': {
              display: 'block',
              marginBottom: 20,
            },
            '@media(min-width: 400px)': {
              alignItems: 'center',
              display: 'flex',
              justifyContent: 'center',
              '> :first-of-type': {
                marginBottom: 0,
                marginRight: 20,
              },
            },
          }}
        >
          <a
            href="https://www.nvdietist.nl/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <Img fixed={data.nvd.childImageSharp.fixed} />
          </a>
          <a
            href="https://www.kwaliteitsregisterparamedici.nl/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <Img fixed={data.kp.childImageSharp.fixed} />
          </a>
        </div>
      </div>
    </Wrapper>
  </Section>
)

const TitleUnderlined = ({ children }) => (
  <div
    css={{
      marginBottom: 50,
      textAlign: 'center',
      [media.navigation]: { marginBottom: 80 },
    }}
  >
    <h2>{children}</h2>
    <div
      css={{
        background: colors.brown,
        height: 3,
        margin: '0 auto',
        width: 80,
      }}
    />
  </div>
)

const Specialisations = () => (
  <Section>
    <Box>
      <Wrapper>
        <TitleUnderlined>Specialisaties</TitleUnderlined>
        <div
          css={{
            marginBottom: 40,
            '> *': { marginBottom: 40 },
            '> :last-child': { marginBottom: 0 },
            '@media(min-width: 700px)': {
              display: 'flex',
              marginBottom: 80,
              '> *': { marginBottom: 0 },
              '> :first-of-type': { marginRight: 40 },
              '> :last-child': { display: 'none' },
            },
            '@media(min-width: 1100px)': {
              '> *, > :first-of-type': { marginRight: 60 },
              '> :last-child': { display: 'block', marginRight: 0 },
            },
          }}
        >
          <Specialisation
            title="Afvallen"
            moreLink="/specialisaties/afvallen"
            moreLabel="Meer over afvallen"
          >
            <p>
              Hulp bij afvallen is waarschijnlijk de meest gestelde vraag aan de
              diëtist. We kijken samen naar een duurzame manier van afvallen, om
              een langdurig resultaat te boeken.
            </p>
            <p>
              Veel mensen hebben al eens geprobeerd af te vallen met behulp van
              een standaard diëet. Echter is elk lichaam anders en vraagt daarom
              altijd om een unieke en persoonlijke aanpak.
            </p>
          </Specialisation>

          <Specialisation
            title="Veganisme"
            moreLink="/specialisaties/veganisme"
            moreLabel="Meer over veganisme"
          >
            <p>
              Plantaardig eten is niet alleen beter voor het milieu en uiteraard
              voor de dieren, maar in veel gevallen ook voor je eigen
              gezondheid.
            </p>
            <p>
              Een (gezonde) vegan krijgt over het algemeen minder verzadigd vet
              en cholesterol binnen en juist meer vezels, vitamines en
              mineralen.
            </p>
          </Specialisation>

          <Specialisation
            title="De FODMaP methode"
            moreLink="/specialisaties/fodmap"
            moreLabel="Meer over de FODMaP methode"
          >
            <p>
              Meer dan één miljoen Nederlanders heeft PDS (prikkelbare darm
              syndroom). Een opgeblazen gevoel, buikpijn en een verstoorde
              stoelgang met diarree en/of obstipatie zijn symptomen die vaak
              voorkomen bij PDS.
            </p>
            <p>
              Met FODMaP methode laat je bepaalde voedingsmiddelen weg om te
              achterhalen wat de grootste oorzaken van PDS zijn.
            </p>
          </Specialisation>
        </div>
        <Button type="ghost" to="/specialisaties">
          Bekijk al mijn specialisaties
        </Button>
      </Wrapper>
    </Box>
  </Section>
)

const Specialisation = ({ title, children, moreLink, moreLabel }) => (
  <div
    css={{
      flexBasis: '100%',
      textAlign: 'left',
    }}
  >
    <h3>{title}</h3>
    <div>{children}</div>
    <ReadMore to={moreLink}>{moreLabel}</ReadMore>
  </div>
)

export default ({ data }) => (
  <Layout>
    <Header
      background={headerImage}
      styles={{
        minHeight: 300,
        [media.medium]: { minHeight: 400 },
        [media.navigation]: { minHeight: 800 },
      }}
    >
      <Wrapper
        css={{
          alignItems: 'center',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          marginTop: -30,
          [media.medium]: { marginTop: -50 },
          [media.navigation]: { marginTop: -100 },
        }}
      >
        <h1
          css={{
            color: 'white',
            lineHeight: '1.25em',
            marginBottom: '0.4em',
            textAlign: 'center',
            [media.navigation]: {
              fontSize: '4.375em',
            },
          }}
        >
          Voedingsadvies op maat
        </h1>
        <Button
          styles={{
            [media.medium]: {
              borderRadius: '30px',
              paddingBottom: '1em',
              paddingTop: '1em',
            },
          }}
          to="/contact"
        >
          Neem contact op
        </Button>
      </Wrapper>
    </Header>

    <Intro data={data} />
    <Specialisations />

    <Section>
      <Wrapper css={{ textAlign: 'center' }} contentWidth={840}>
        <TitleUnderlined>Tarieven &amp; Vergoedingen</TitleUnderlined>
        <Prices />
        <p>
          De kosten voor dieetadvisering worden meestal volledig of voor een
          groot deel vergoed door de zorgverzekeraar.
        </p>
        <ReadMore to="/tarieven-vergoedingen">
          Lees meer over vergoedingen
        </ReadMore>
      </Wrapper>
    </Section>
  </Layout>
)

export const query = graphql`
  query Images {
    lieke: file(relativePath: { regex: "/lieke/" }) {
      childImageSharp {
        fluid(maxWidth: 320) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    nvd: file(relativePath: { regex: "/nvd/" }) {
      childImageSharp {
        fixed(width: 184, height: 77) {
          ...GatsbyImageSharpFixed_noBase64
        }
      }
    }
    kp: file(relativePath: { regex: "/kwaliteitsregister/" }) {
      childImageSharp {
        fixed(width: 97, height: 100) {
          ...GatsbyImageSharpFixed_noBase64
        }
      }
    }
  }
`
